import { configureStore, createSlice } from "@reduxjs/toolkit";

interface WebState {
  connectingWallet: boolean;
}

const initialState: WebState = {
  connectingWallet: false,
};

export const webSlice = createSlice({
  name: "web",
  initialState,
  reducers: {
    connectWallet: (state) => {
      state.connectingWallet = true;
    },
    walletIsConnected: (state) => {
      state.connectingWallet = false;
    },
  },
});

export const { connectWallet, walletIsConnected } = webSlice.actions;

export type RootState = ReturnType<typeof store.getState>;

export const selectConnectingWallet = (state: RootState) =>
  state.web.connectingWallet;

export const store = configureStore({
  reducer: {
    web: webSlice.reducer,
  },
});
